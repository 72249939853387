<template>
  <div
    id="integrations-panel"
    :class="fullscreen ? 'integrations-panel--fullscreen' : 'integrations-panel'"
  >
    <div
      slot="header"
      class="integrations-panel--toolbar"
    >
      <header class="toolbar-btn-wrapper">
        <label
          v-show="!fullscreen"
          for="open-fullscreen-btn"
          class="toolbar-btn--label"
          :style="{ color: primaryColor }"
        >
          Tela cheia
        </label>

        <button
          id="open-fullscreen-btn"
          class="toolbar-btn"
          :style="{ color: primaryColor }"
          @click="toggleFullscreen"
        >
          <i :class="fullscreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen'" />
        </button>
      </header>
    </div>

    <main class="integrations-panel--content">
      <h2 class="loading">
        Carregando...
      </h2>

      <iframe
        id="dashboard"
        class="iframe"
        :src="embedUrl"
      />
    </main>
  </div>
</template>

<script>
/* Services */
import LookerBI from '@/services/looker-bi';

const lookerService = new LookerBI();

export default {
  name: 'IntegrationsPanel',
  data: () => ({
    embedUrl: '',
    fullscreen: false,
  }),
  computed: {
    primaryColor() {
      return this.$store.state.configBox.cor1;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    panelId() {
      return 'PainelIntegracoes';
    },
  },
  mounted() {
    this.getLookerEmbed(this.panelId);
  },
  methods: {
    /* API */
    getLookerEmbed(dashboardId) {
      this.$store.dispatch('set_isLoadingActive', true);

      lookerService.getLookerEmbed(
        this.ownerId,
        dashboardId,
        this.token,
      )
        .then(
          (resp) => {
            this.embedUrl = resp;

            this.$store.dispatch('set_isLoadingActive', false);
          },
          (err) => {
            alert(err);

            this.$store.dispatch('set_isLoadingActive', false);
          }
        );
    },
    /* Helpers */
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
  },
}
</script>

<style scoped lang="scss">
.integrations-panel {
  min-height: 100vh;
  width: 100%;

  .integrations-panel--toolbar {
    align-items: center;
    background-color: white;
    border: 0;
    border-radius: 0.3rem 0.3rem 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0.25rem;
    transition: 0.25s ease-in-out;
  }

  .integrations-panel--content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    min-height: 100vh;
    width: 100%;

    .iframe {
      border: 0;
      min-height: 100vh;
      outline: 0;
      width: 100%;
      z-index: 98;
    }
  }
}

.integrations-panel--fullscreen {
  .integrations-panel--toolbar {
    align-items: center;
    background-color: white;
    border: 0;
    border-radius: 0.3rem 0.3rem 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0.25rem;
    transition: 0.25s ease-in-out;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 15002;

    .toolbar-btn-wrapper {
      .toolbar-btn--label {
        display: none;
      }
    }
  }

  .integrations-panel--content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    .iframe {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 15001;
    }
  }
}

.toolbar-btn-wrapper {
  align-items: center;
  display: flex;
  gap: 0.5rem;

  .toolbar-btn--label {
    font-size: 0.875em;
    font-weight: 600;
    margin: 0;
  }

  .toolbar-btn {
    appearance: none;
    align-items: center;
    background: #FFFFFF1b;
    border: 0;
    border-radius: 0.3rem;
    display: flex;
    font-size: 1.25rem;
    height: 36px;
    justify-content: center;
    outline: 0;
    transition: 0.25s ease-in-out;
    vertical-align: middle;
    width: 36px;
  }

  .toolbar-btn:hover {
    font-size: 1.75rem;
  }
}

.loading {
  color: rgb(58, 66, 69);
  font-family:
    Roboto,
    "Noto Sans",
    "Noto Sans JP",
    "Noto Sans CJK KR",
    "Noto Sans Arabic UI",
    "Noto Sans Devanagari UI",
    "Noto Sans Hebrew",
    "Noto Sans Thai UI",
    Helvetica,
    Arial,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
  position: absolute;
  z-index: 0;
}
</style>

<style>
#app .main-wrapper.main-wrapper-default .main-container-fluid.py-2 {
  min-height: 100% !important;
}

.integrations-panel--toolbar:hover {
  padding: 0.5rem;
}
</style>
