import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const baseEnv = process.env.VUE_APP_BASEENV;

const baseUrl = `${baseDomain}/${baseEnv}`;

/* Looker BI Service */
export default class LookerBI {
  /**
   * Returns the Embed URL for the informed Looker Dashboard or Look.
   * 
   * @param {number} ownerId
   * @param {string} dashboardId
   * @returns An authenticated Looker embed URL.
   */
  getLookerEmbed(ownerId, dashboardId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/bi/getlookerurl/${ownerId}/${dashboardId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(url, headers).then(
        (resp) => {
          if (resp.data.header.codigo === 1) {
            resolve(resp.data.data);
          } else {
            reject(resp.data.header.msg);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
